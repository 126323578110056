
<template>
    <div class="row gy-2">
      <div class="col-12">
        <div class="row gy-2 justify-content-between">
          <div class="col-lg-5 d-flex">
            <div class="dropdown no-arrow me-2">
              <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bx bx-menu"></i>
              </a>
              <ul class="dropdown-menu">
                <li @click.prevent="exportUsers()">
                  <button class="dropdown-item" type="button">
                    Export Excel
                  </button>
                </li>
              </ul>
            </div>
            <div class="input-group">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              <router-link class="btn btn-primary" to="/users/create">
              <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Add User </span></router-link>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-group mb-0">
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="my-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <div class="card">
          <div class="card-body p-lg-3">
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm" align="middle">
                <template v-slot:cell(image)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <img class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div style="min-width:5rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span class="text-dark font-sm text-capitalize">{{ data.item.first_name}} {{ data.item.last_name}}</span>
                  </div>
                </template>
                <template v-slot:cell(mobile)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.mobile }}</span>
                  </div>
                </template>
                <template v-slot:cell(email)="data">
                  <div style="min-width:5rem;" v-if="data.item" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.email }}</span>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
  </template>
  
<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue'
  
  export default {
    name: "hospital-users",
    components:{
      IsLoading,
    },
    data() {
      return {
        isLoading: true,
        searchQuery:"",
        pageOptions: [10, 50, 100, 500, 1000],
        columns: [
          {
              key: "image",
              label: "Image",
          },
          {
              key: "name",
              label: "Name",
          },
          {
              key: "mobile",
              label: "Phone",
          },
          {
              key: "email",
              label: "Email",
          },
        ],
        pageData:{
          data: []
        }
      }
    },
    computed: {
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      currentPage: {
        get() {
          return this.pageData.current_page
        },
        set(val) {
          if(!this.pageData.current_page || this.pageData.current_page == val) return
          this.fetchItems(val)
        }
      },
      itemsPerPage: {
        get() {
          return +this.pageData.per_page || 10
        },
        set(val) {
          this.fetchItems(1, val)
        }
      },
      paginationInfo(){
        if(!this.pageData.total) return '0 - 0 of 0';
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
      },
    },
    methods: {
      navigateTo(id){
        if(id == this.activeUser.id){
          this.$router.push({path: '/account'});
        }else{
          this.$router.push({path:`/users/${id}`});
        }
      },
      updateSearchQuery: _.debounce(function(string) {
        this.fetchItems()
      }, 2000),
      fetchItems(page, per_page = null){
        let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
                      hospital_id: this.$route.params.hospitalId }
        if(this.searchQuery){ payload.search = this.searchQuery }
        this.isLoading = true
        this.$http.get(`/users?${new URLSearchParams(payload).toString()}`)
          .then((response) => {
            this.isLoading = false
            if(response.data.success){
              this.pageData = response.data.data
            }
          })
      },
      exportUsers() {
        const payload = {
          url: `/users/export-excel?hospital_id=${this.$route.params.hospitalId}`,
          filename: 'hospital-users_'
        }
        this.$store.dispatch("changeLoaderValue", true)
        this.$store.dispatch('serverExport', payload)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.status != 200){
              this.alertError('Could not generate lawyers');
            }
        })
      },
      initializeAll(){
      }
    },
    created() {
      this.fetchItems()
    }
  }
  
  </script>
  
  