
<template>
<form autocomplete="off" class="">
  <is-loading v-if="isLoading" :box="true" />
  <div class="row gx-lg-4 g-3">
    <div class="col-12">
      <label class="form-label" for="inputName">Name</label>
      <input id="inputName" type="text" class="form-control" v-model="data.name"
      :class="{ 'is-invalid': v$.data.name.$error }" placeholder="Name" name="name" />
      <div v-if="v$.data.name.$error"
      class="invalid-feedback">
      <span v-if="v$.data.name.required.$message">
        {{ v$.data.name.required.$message }}</span>
      </div>
    </div>
    <div class="col-md-6">
      <label for="local_government_id" class="form-label">Local government</label>
      <select @change="lgaChange()" v-model="data.local_government_id" 
        name="local_government_id" id="local_government_id" class="form-select">
      <option disabled value="" >--select LGA--</option>
      <option v-for="(lga, coIndex) in lgaOptions" :key="coIndex" 
      :value="lga.id">{{lga.name}}</option>
      </select>
    </div>
    <div class="col-md-6">
      <label> Ward </label>
      <multiselect 
      mode="single"
      searchable
      valueProp="id"
      trackBy="name" label="name"
      :options="wardOptions" 
      v-model="data.registration_area_id" placeholder="--select ward --"></multiselect>

      <div v-for="(item, index) in v$.data.registration_area_id.$errors"
        :key="index" class="text-danger">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>  
    <div class="col-12">
      <label class="form-label">Description</label>
      <textarea v-model="data.description" class="form-control"
      rows="3" placeholder="Write Description" name="description"></textarea>
    </div>
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" 
        @click.prevent="editMode? update() : create()"
            type="button">{{editMode? 'Update':'Submit'}}</b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import Multiselect from '@vueform/multiselect'

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    IsLoading,
    Multiselect
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      data: {
        name: "",
        description: "",
        local_government_id: "",
        registration_area_id: ""
      },
      mirrorData: null,
      wardOptions: []
    }
  },
  validations() {
    return {
      data:{
        name: {
          required: helpers.withMessage("Name is required", required),
        },
        registration_area_id: {
          required: helpers.withMessage("Choose ward", required),
        },
      }
    }
  },
  computed:{
    lgaOptions(){
      return this.$store.state.localGovernments
    }
  },
  methods: {
    create() {
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/hospitals/create', formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('addItem', response.data.data)
            this.$store.commit("hospital/ADD_ITEM", response.data.data)
            this.resetForm();
          }
        })
      })
    },
    update(){
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/hospitals/${this.editItem.id}/update`,formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$emit('updateItem', response.data.data)
              this.resetForm()
            }
          })
      
      })
    },
    fetchLgas(){
      this.isLoading = true;
      this.$store.dispatch('localGovernments')
          .then((response) => this.isLoading = false )
    },
    lgaChange(initialize = true){
      if(initialize){
        this.data.registration_area_id = "";
      }
      this.wardOptions = [];
      if(!this.data.local_government_id){ return }
      this.isLoading = true;
      this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.wardOptions = response.data.data
          }
      })
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit('closeMe')
    },
  },
  mounted(){
    this.fetchLgas()
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
      this.data.local_government_id = this.editItem.ward?.local_government_id;
      this.lgaChange(false)
    }
  }
}
</script>
