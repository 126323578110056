
<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">{{ title }}</h5>
      </div>
      <div class="card-body p-lg-3">
        <div class="table-responsive mb-0 loading-viewport">
          <is-loading v-if="isLoading" :box="true" />
          <b-table striped hover ref="selectableTable" :items="data"
            :fields="columns" responsive="sm" align="middle">
            <template v-slot:cell(ailment)="data">
              <div style="min-width:2rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                {{ data.item.title }}
              </div>
            </template>
            <template v-slot:cell(count)="data">
              <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                {{ data.item.ailment_count }}
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
  </template>
  
<script>

import IsLoading from '@/components/IsLoading.vue';
  
export default {
  name: "top-ailments",
  props:{
    hospitalId: {
      type: Number,
      default: null
    },
    title:{
      default: 'Top Five Ailments'
    }
  },
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          key: "ailment",
          label: "Ailment",
        },
        {
          key: "count",
          label: "Count",
        },
      ],
      data:[]
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/ailments/`});
    },
    fetchItems(){
      let payload = {}
      if(this.hospitalId){ payload.hospital_id = this.hospitalId }
      this.isLoading = true
      this.$http.get(`/analytics/top-ailments?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.data = response.data.data
          }
        })
    },
  },
  mounted() {
    this.fetchItems()
  },
}

</script>
  
  